import general from './modules/general.json';
import messages from './modules/messages.json';
import menu from './modules/menu.json';
import dashboard from './modules/dashboard.json';
import plugin from './modules/plugin.json';
import pager from './modules/pager.json';
import driver from './modules/driver.json';
import status from './modules/status.json';
import catalog from './modules/catalog.json';
import fiscalEntity from './modules/fiscalEntity.json';
import address from './modules/address.json';
import rules from './modules/rules.json';
import form from './modules/form.json';

export default {
  general,
  messages,
  menu,
  dashboard,
  plugin,
  pager,
  driver,
  status,
  catalog,
  fiscalEntity,
  address,
  rules,
  form
};
