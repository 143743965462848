/* eslint-disable prettier/prettier */
import { createApp } from 'vue';
import App from './App.vue';

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import i18n from '@/core/plugins/i18n';

//imports for app initialization
import ApiService from '@/core/services/ApiService';
import { initApexCharts } from '@/core/plugins/apexcharts';
import { initInlineSvg } from '@/core/plugins/inline-svg';
import { initVeeValidate } from '@/core/plugins/vee-validate';

//Syncfusion
import { registerLicense } from '@syncfusion/ej2-base';
import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';
import { MultiSelectPlugin } from '@syncfusion/ej2-vue-dropdowns';
import {
  GridPlugin,
  Grid,
  Page,
  CommandColumn,
  ColumnChooser,
  Toolbar,
  Search,
  Sort,
  PdfExport,
  ExcelExport,
  Reorder,
  Resize
} from '@syncfusion/ej2-vue-grids';
import { TooltipPlugin } from '@syncfusion/ej2-vue-popups';
import { TabPlugin } from '@syncfusion/ej2-vue-navigations';
import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns';
import '@/core/plugins/prismjs';

const app = createApp(App);

if (process.env.VUE_APP_SYNCFUSION_KEY)
  registerLicense(process.env.VUE_APP_SYNCFUSION_KEY);

MultiSelect.Inject(CheckBoxSelection);
Grid.Inject(
  Page,
  CommandColumn,
  ColumnChooser,
  Toolbar,
  Search,
  Sort,
  PdfExport,
  ExcelExport,
  Reorder,
  Resize
);

app
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(MultiSelectPlugin)
  .use(GridPlugin)
  .use(TooltipPlugin)
  .use(DropDownListPlugin)
  .use(TabPlugin);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount('#app');
