enum Actions {
  // action types
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  UPDATE_USER = 'updateUser',
  FORGOT_PASSWORD = 'forgotPassword',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  LOGIN_WORKSPACE = 'LoginWorkspace',
  SET_WORKSPACES = 'setWorkspaces',
  SET_PLAZA_ID = 'setPlazaId',
  SET_PLAZAS = 'set_plazas',
  SET_WORKSPACE = 'setWorkspace',
  SET_AUTOREFRESH = 'setAutorefresh'
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_WORKSPACES = 'SET_WORKSPACES',
  SET_SLUG = 'SET_SLUG',
  SET_LOGO = 'SET_LOGO',
  SET_COMPANY = 'SET_COMPANY',
  SET_PLAZA_ID = 'SET_PLAZA_ID',
  SET_PLAZAS = 'SET_PLAZAS',
  SET_WORKSPACE = 'SET_WORKSPACE',
  SET_TOKEN = 'SET_TOKEN',
  SET_CODE = 'SET_CODE',
  SET_LOADING = 'SET_LOADING',
  SET_AUTOREFRESH = 'SET_AUTOREFRESH'
}

export { Actions, Mutations };
