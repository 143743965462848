import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Mutation, Module, VuexModule, Action } from 'vuex-module-decorators';
import { loginWorkspace } from '@/core/api/auth';

/*const crypto = require("crypto");

const JELP_HEX = "6a656c70";*/

export interface user {
  state: {
    user: string;
    status: string;
    code: string;
    username: string;
    avatar: string;
    introduction: string;
    roles: Array<string>;
    setting: {
      articlePlatform: Array<string>;
    };
    id: Array<string>;
    location: object;
    businessUnit: Array<string>;
    firebaseToken: Array<string>;
    fullName: Array<string>;
  };
}
@Module
export default class UserModule extends VuexModule {
  @Mutation
  [Mutations.SET_CODE](state, code) {
    state.code = code;
  }
  @Mutation
  [Mutations.SET_TOKEN](state, token) {
    state.token = token;
  }
  @Action
  async [Actions.LOGIN_WORKSPACE](workspace: string) {
    try {
      const res = await loginWorkspace(workspace);

      if (res && res.data) {
        return res.data;
      }
    } catch (err) {
      this.context.commit(Mutations.SET_ERROR, err);
    }
  }
}
