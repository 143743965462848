import { userLogin, getUserProfile } from '@/core/api/auth';
import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { useStore } from 'vuex';
export interface User {
  username: string;
  token: string;
  client_i: string;
  grant_type: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = false;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }
  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }
  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.errors = {};

    if (user) this.user = user;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
  }

  @Action
  async [Actions.LOGIN](credentials: { username: string; password: string }) {
    try {
      const authToken = await userLogin(
        credentials.username,
        credentials.password
      );

      this.context.commit(Mutations.SET_AUTH);

      return authToken.data;
    } catch (err) {
      this.context.commit(Mutations.SET_ERROR, err);
    }
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }
  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post('', credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post('forgot_password', payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
    try {
      const store = useStore();
      const user = await getUserProfile();
      const params = window.location.search;
      const urlParams = new URLSearchParams(params);
      const _wsIdParam = urlParams.get('ws');
      const _plazaIdParam = urlParams.get('plaza');

      this.context.commit(Mutations.SET_AUTH, user.data);
      if (_wsIdParam && _plazaIdParam) {
        const resp = await this.context.dispatch(
          Actions.LOGIN_WORKSPACE,
          _wsIdParam
        );

        if (resp) {
          await this.context.dispatch(Actions.SET_PLAZA_ID, _plazaIdParam);
          await this.context.dispatch(Actions.SET_PLAZAS, resp.plazas);
          await this.context.dispatch(Actions.SET_WORKSPACE, resp.workspace);
        }
      }

      const workspaces = store.getters.getWorkspaces;

      if (!workspaces) {
        console.log('no workspaces');
      }
      return true;
    } catch (err) {
      let message = err;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (err.response) {
        const error = err as SADResError;

        message = error.response.data.info;
      }

      this.context.commit(Mutations.SET_ERROR, [message]);

      return false;
    }
  }
}
