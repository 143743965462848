import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { Mutations, Actions } from '@/store/enums/StoreEnums';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';

// route modules
import Catalog from './modules/catalog';
import Settings from './modules/settings';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/controltower',
    component: () => import('@/layout/Layout.vue'),
    meta: {
      main: true
    },
    children: [
      {
        path: '/controltower',
        name: 'controltower',
        component: () => import('@/views/controlTower/index.vue'),
        meta: {
          svgIcon: '/media/icons/duotune/finance/fin001.svg',
          fontIcon: 'bi-app-indicator'
        }
      },
      ...Catalog
    ]
  },
  {
    path: '/',
    component: () => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('@/views/authentication/MultiStepSignUp.vue')
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignUp.vue')
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/PasswordReset.vue')
      }
    ]
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/crafted/authentication/Error404.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const isAuth = await store.dispatch(Actions.VERIFY_AUTH);

  if (isAuth) setCurrentPageTitle((to.name as string) || '');

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
