import { createI18n } from 'vue-i18n';

// langs
import es from '@/lang/es';
import en from '@/lang/en';

const messages = {
  es,
  en
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  fallbackLocale: 'es',
  messages
});

export default i18n;
