import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Workspace } from '@/types/workspace';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
export interface User {
  username: string;
  password: string;
  token: string;
  client_i: string;
  grant_type: string;
}

@Module
export default class WorkSpaceModule extends VuexModule {
  workspaces: Workspace[] = [];
  workspace: Workspace | undefined;
  slug: string | undefined;
  logo: string | undefined;
  company: string | undefined;
  /**
   * Get workspaces
   * @returns array
   */
  get getWorkspaces() {
    return this.workspaces;
  }
  /**
   * Get current workspace object
   * @returns Object
   */
  get currentWorkspace() {
    return this.workspace;
  }

  get accountId() {
    if (!this.workspace) return null;

    return this.workspace.account?.id || null;
  }

  @Mutation
  [Mutations.SET_WORKSPACES](workspaces: Workspace[]) {
    this.workspaces = JSON.parse(JSON.stringify(workspaces));
  }
  @Mutation
  [Mutations.SET_WORKSPACE](workspace) {
    this.workspace = workspace;
  }
  @Mutation
  [Mutations.SET_SLUG](slug) {
    this.slug = slug;
  }
  @Mutation
  [Mutations.SET_LOGO](logo) {
    this.logo = logo;
  }
  @Mutation
  [Mutations.SET_COMPANY](company) {
    this.company = company;
  }
  @Action
  [Actions.SET_WORKSPACE](workspace) {
    this.context.commit(Mutations.SET_SLUG, workspace.slug);
    this.context.commit(Mutations.SET_LOGO, workspace.logo);
    this.context.commit(Mutations.SET_COMPANY, workspace.company);
    this.context.commit(Mutations.SET_WORKSPACE, workspace);
  }
  @Action
  [Actions.SET_WORKSPACES](workspaces) {
    this.context.commit(Mutations.SET_WORKSPACES, workspaces);
  }
}
