import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { getCookie, setCookie } from 'typescript-cookie';
import moment from 'moment';

const autorefreshCookie = false;

@Module
export default class TasksListModule extends VuexModule {
  tab = 'pending';
  autorefresh = autorefreshCookie;
  dateRangeFolios = getCookie('dateRangeFolios') || [
    moment(new Date()).format('YYYY-MM-DD'),
    moment(new Date()).format('YYYY-MM-DD')
  ];
  /**
   * Get state
   * @returns {boolean}
   */
  get getAutoRefresh() {
    return this.autorefresh;
  }
  @Mutation
  [Mutations.SET_AUTOREFRESH](autorefresh: boolean) {
    this.autorefresh = autorefresh;
    setCookie('autorefresh', autorefresh);
  }
  @Action
  [Actions.SET_AUTOREFRESH](autorefresh: boolean) {
    this.context.commit(Mutations.SET_AUTOREFRESH, autorefresh);
  }
}
