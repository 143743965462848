import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Plaza } from '@/types/plaza';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
@Module
export default class PlazaModule extends VuexModule {
  //states
  plazas: Plaza[] = [];
  plaza: string | undefined;

  //getters
  get currentPlaza() {
    return this.plaza;
  }

  get allPlazas() {
    return this.plazas;
  }

  //Mutations
  @Mutation
  [Mutations.SET_PLAZAS](plazas: Plaza[]) {
    this.plazas = [...plazas];
  }

  @Mutation
  [Mutations.SET_PLAZA_ID](plaza) {
    this.plaza = plaza;
  }

  //Actions
  @Action
  [Actions.SET_PLAZA_ID](plaza) {
    this.context.commit(Mutations.SET_PLAZA_ID, plaza);
  }
  @Action
  [Actions.SET_PLAZAS](plazas) {
    this.context.commit(Mutations.SET_PLAZAS, plazas);
  }
}
