import { App } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { AxiosRequestConfig } from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import i18n from '@/core/plugins/i18n';
import { Actions } from '@/store/enums/StoreEnums';
import store from '@/store/';
import { useRouter } from 'vue-router';

const router = useRouter();

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.defaults.withCredentials = true;
  }

  /**
   * @description set the default HTTP request headers
   */

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(resource: string, params: AxiosRequestConfig): any {
    return ApiService.vueInstance.axios
      .get(resource, params)
      .catch(httpErrorHandler);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string, slug = '' as string): any {
    const url = slug.length ? `${resource}/${slug}` : `${resource}`;
    const response = ApiService.vueInstance.axios
      .get(url)
      .then((resp) => {
        return resp;
      })
      .catch(httpErrorHandler);

    return response;
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, data?: object): any {
    return ApiService.vueInstance.axios
      .post(resource, data)
      .catch(httpErrorHandler);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(resource: string, params: AxiosRequestConfig): any {
    return ApiService.vueInstance.axios
      .put(resource, params)
      .catch(httpErrorHandler);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): any {
    return ApiService.vueInstance.axios
      .delete(resource)
      .catch(httpErrorHandler);
  }
}

function httpErrorHandler(err) {
  const { status, statusText, data } = err.response;

  switch (status) {
    case 400:
      Swal.fire({
        icon: 'error',
        title: data.error.message,
        showConfirmButton: false,
        timer: 5 * 1000
      });
      break;
    case 401:
      Swal.fire({
        text: i18n.global.t('messages.sessionExpired'),
        icon: 'warning',
        buttonsStyling: false,
        showConfirmButton: false,
        timer: 5 * 1000,
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      });
      router.push({ name: 'sign-in' });
      store.dispatch(Actions.LOGOUT);
      break;
    case 500:
      switch (data.code) {
        case 'auth_recovery_password_user_not_found':
          Swal.fire({
            icon: 'warning',
            title: i18n.global.t('messages.dontHavePasswordRequest'),
            showConfirmButton: false,
            timer: 5 * 1000
          });
          break;
        default:
          Swal.fire({
            icon: 'error',
            title: data.status === 500 ? data.data.info : data.info,
            showConfirmButton: false,
            timer: 5 * 1000
          });
          break;
      }
      break;
    default:
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('messages.pleaseContactJelpSupport'),
        showConfirmButton: false,
        timer: 5 * 1000
      });
      break;
  }
}
export default ApiService;
