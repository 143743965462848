import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import UserModule from '@/store/modules/UserModule';
import WorkSpaceModule from '@/store/modules/WorkSpaceModule';
import TasksListModule from '@/store/modules/TasksList';
import PlazaModule from '@/store/modules/PlazaModule';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['PlazaModule', 'WorkSpaceModule', 'ConfigModule']
});

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UserModule,
    WorkSpaceModule,
    TasksListModule,
    PlazaModule
  },
  plugins: [vuexLocal.plugin]
});

export default store;
