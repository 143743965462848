import ApiService from '@/core/services/ApiService';
import { User } from '@/types/user';
import { Workspace, WorkspaceUser } from '@/types/workspace';

export interface UserLoginResponse {
  /**
   * @deprecated
   */
  access_token: string;
  expires_in: number;
  token_type: string;
  workspaces: Workspace[];
}

export async function userLogin(
  identifier: string,
  password: string,
  v = 1
): Promise<SADRes<UserLoginResponse>> {
  return ApiService.post(`/auth/v${v}/token`, {
    password,
    username: identifier,
    client_id: process.env.VUE_APP_CLIENT_ID,
    grant_type: process.env.VUE_APP_GRANT_TYPE
  }).then((res) => res.data);
}

export async function loginWorkspace(
  workspaceId: string,
  v = 1
): Promise<SADRes<WorkspaceUser>> {
  return ApiService.post(`/auth/v${v}/workspace/login`, {
    workspaceId
  }).then((res) => res.data);
}

export async function getUserProfile(v = 2): Promise<SADRes<User>> {
  return ApiService.get(`/admin/v${v}/user/profile`).then((res) => res.data);
}
